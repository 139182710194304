import { computed, reactive } from 'vue';
import { DEFAULT_NETWORK_ID } from '@/helpers/networkParams.helper';
import {
  ADA_FOR_MILKOMEDA_WHEN_CARDANO,
  calculateMaxAmountADAWhenUnwrapFromMilkomeda,
  normalizeAmountByMinDecimals,
} from '@/helpers/milkomeda-wrapped-smartcontract/milkomeda-wsc-calculation';
import { Token } from '@/sdk/entities/token';
import { useTokens } from '@/store/modules/tokens/useTokens';
import { useBalances } from '@/store/modules/tokens/useBalances';

const LOGGER = {
  groupCollapsed: (...label: any[]) => {
    if (isLoggingDisabled()) return;

    console.groupCollapsed(...label);
  },
  groupEnd: () => {
    if (isLoggingDisabled()) return;

    console.groupEnd();
  },
  log: (message?: any, ...optionalParams: any[]) => {
    if (isLoggingDisabled()) return;

    console.log(message, ...optionalParams);
  },
};

export interface IMilkomedaWSCBalanceADAIntoMilkomedaValidatorState {
  minADABalance: Readonly<string>;
}

export function useMilkomedaWSCBalanceADAIntoMilkomedaValidator() {
  const { getTokenBySymbolAndChainId } = useTokens();
  const { balanceByTokenSymbolAndChainId } = useBalances();

  const milkomedaWSCBalanceADAIntoMilkomedaValidatorState =
    reactive<IMilkomedaWSCBalanceADAIntoMilkomedaValidatorState>({
      minADABalance: ADA_FOR_MILKOMEDA_WHEN_CARDANO.toString(),
    });

  const milkomedaADAToken = computed<Token>(() => {
    return getTokenBySymbolAndChainId('ADA', +DEFAULT_NETWORK_ID!);
  });

  function $reset(): void {
    milkomedaWSCBalanceADAIntoMilkomedaValidatorState.minADABalance =
      ADA_FOR_MILKOMEDA_WHEN_CARDANO.toString();
  }

  function validateADABalanceForMilkomeda(): boolean {
    LOGGER.groupCollapsed('[MILKOMEDA_WSC:UNWRAP_BRIDGE] validateADABalanceForMilkomeda');

    const balanceADAIntoMilkomedaByMinDecimals = normalizeBalanceTokenIntoMilkomeda(
      milkomedaADAToken.value,
    );

    const isValid = balanceADAIntoMilkomedaByMinDecimals.gte(
      milkomedaWSCBalanceADAIntoMilkomedaValidatorState.minADABalance,
    );

    LOGGER.log(
      'Balance ADA into Milkomeda [ ADA ] : ',
      balanceADAIntoMilkomedaByMinDecimals.toString(),
    );
    LOGGER.log(
      'MIN balance ADA into Milkomeda [ ADA ] : ',
      milkomedaWSCBalanceADAIntoMilkomedaValidatorState.minADABalance.toString(),
    );
    LOGGER.log('is valid balance ADA into Milkomeda : ', isValid);
    LOGGER.groupEnd();
    return isValid;
  }

  function normalizeBalanceTokenIntoMilkomeda(milkomedaToken: Token) {
    const balanceTokenIntoMilkomedaInToken =
      balanceByTokenSymbolAndChainId(
        milkomedaToken.symbol!,
        +DEFAULT_NETWORK_ID!,
      ).value?.balance.toExact() ?? '0';

    return normalizeAmountByMinDecimals(balanceTokenIntoMilkomedaInToken, milkomedaToken);
  }

  function calculateMaxADAIntoMilkomedaWhenUnwrapADA() {
    const balanceADAIntoMilkomedaByMinDecimals = normalizeBalanceTokenIntoMilkomeda(
      milkomedaADAToken.value,
    );

    return calculateMaxAmountADAWhenUnwrapFromMilkomeda(balanceADAIntoMilkomedaByMinDecimals);
  }

  return {
    milkomedaWSCBalanceADAIntoMilkomedaValidatorState,
    validateADABalanceForMilkomeda,
    calculateMaxADAIntoMilkomedaWhenUnwrapADA,
    $reset,
  };
}

// DEBUG

function isLoggingDisabled() {
  return !window['BLUESHIFT_DEBUG'].WSC;
}
